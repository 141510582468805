import React, { Component } from "react";
// import ReactDOM             from 'react-dom';
// import AgentRecevingCall    from './agent_recevingcall.js';
import { Input, Row, Col, Button, ProgressBar } from "react-materialize";
import * as $ from "jquery";
import Modal from "react-modal";
import RecevingSearch from "./components/receiving_search";
import CardPanel from "react-materialize/lib/CardPanel";
import { matchPath } from "react-router";
import { AuthProvider } from "oidc-react";

document.body.style.backgroundColor = "#eee";

const oidcConfig = {
  onSignIn: async () => {
    console.log("Onsign");
    localStorage.clear();
    window.location.hash = "";
    await updateAccessToken();
  },
  authority: process.env.REACT_APP_ISSUER_AGENT,
  clientId: process.env.REACT_APP_CLIENT_ID_AGENT,
  redirectUri: `${process.env.REACT_APP_DOMAIN}${window.location.pathname}`,
  silent_redirect_uri: `${process.env.REACT_APP_DOMAIN}${window.location.pathname}`,
  response_type: "id_token token",
  scope: "openid profile email",
  automaticSilentRenew: true,
  filterProtocolClaims: true,
  loadUserInfo: true,
  monitorSession: true,
};

const updateAccessToken = () => {
  var token_parse = JSON.parse(
    sessionStorage.getItem(
      `oidc.user:${process.env.REACT_APP_ISSUER_AGENT}:${process.env.REACT_APP_CLIENT_ID_AGENT}`,
    ),
  );
  console.log("token_parse", token_parse);
  if (token_parse !== null) {
    localStorage.setItem("access_token", token_parse.access_token);
    fetch(`${process.env.REACT_APP_URL_API}/v3/verifyagent`, {
      method: "POST",
      headers: { Authorization: `Bearer ${token_parse.access_token}` },
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        localStorage.setItem("family_name", response.data.family_name);
        localStorage.setItem("given_name", response.data.given_name);
        localStorage.setItem("preferred_username", response.data.preferred_username);
        const match = matchPath(window.location.pathname, {
          path: "/v3/receiving/:threadid",
          exact: true,
          strict: false,
        });
        updateAgentUsername(match.params.threadid, response.data.preferred_username);
      });
  }
};
const updateAgentUsername = (threadid, agentusername) => {
  fetch(`${process.env.REACT_APP_URL_API}/v3/agent/updateagentusername`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
    body: JSON.stringify({
      threadid: threadid,
      preferred_username: agentusername,
    }),
  })
    .then((response) => {
      return response.json();
    })
    .then((data) => {});
};
class ReceivingApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      urlWS: process.env.REACT_APP_URL_WS,
      urlAPI: process.env.REACT_APP_URL_API,
      agentid: props.agentid,
      modalIsOpen: false,
      loading: <div></div>,
      loginConnect: "",
      searchInputName: "",
      searchInputGood: "",
      searchType: "lastcontact",
      searchFrom: "livechat",
      searchData: null,
      searchInput: "",
      searchInputDisabled: true,
      token: "",
    };
  }
  UNSAFE_componentWillMount() {
    Modal.setAppElement("body");
    document.title = "TTRS Live Chat Reveiving Call";
    this.checkThreadStatus();
  }
  onSearchInputChange(event) {
    this.setState({
      searchInput: event.target.value,
    });
  }
  checkThreadStatus() {
    // $.ajax({
    //     url: `${this.state.urlAPI}/api/channel/checkstatus`,
    //     type : "POST",
    //     data : {
    //         threadid : localStorage.getItem("receviving_threadid")
    //     },
    //     dataType: 'json',
    //     crossDomain: true,
    //     success: function(data) {
    //         if(data.threadstatus === "receivingcall" || data.threadstatus === "chat"){
    //             this.setState({
    //                 identification : data.data.identification
    //             })
    //             this.checkClientDetail();
    //             localStorage.setItem("receviving_threadstatus", data.threadstatus);
    //         }else{
    //             localStorage.setItem("receviving_threadid", null);
    //             localStorage.setItem("receviving_threadstatus", null);
    this.searchFromLiveChat();
    //     }
    // }.bind(this)
    // });
  }
  checkClientDetail() {
    var obj = {
      controller: "user",
      action: "showdetailusers",
      identification: this.state.identification,
    };
    var test = {
      params: JSON.stringify(obj),
      appkey: "3D569F9F115833DD",
    };
    $.ajax({
      url: `https://api.ttrs.or.th/api.php`,
      data: test,
      type: "POST",
      dataType: "json",
      beforeSend: function () {
        this.setState({
          loading: <ProgressBar className="blue" />,
        });
      }.bind(this),
      success: function (data) {
        if (data.status === "OK") {
          // if(localStorage.getItem("receviving_threadstatus") === "receivingcall" || localStorage.getItem("receviving_threadstatus") === "chat"){
          //     this.setState({
          //         userdata : data.userdata,
          //         loading  : <div></div>
          //     });
          //     this.openChat();
          // }else{
          // this.setState({
          //     userdata : data.userdata,
          //     loading  : <div></div>,
          //     modalIsOpen : true
          // });
          // }
        } else {
          this.setState({
            loading: (
              <div align="center" style={{ color: "red" }}>
                {data.message}
              </div>
            ),
          });
        }
      }.bind(this),
    });
  }

  searchUser() {
    this.searchFromLiveChat();
  }

  searchFromLiveChat() {
    var datapost = {
      searchCondition: null,
      name: null,
      good: null,
    };

    if (this.state.searchInputName.trim() === "" && this.state.searchInputGood.trim() === "") {
      datapost.searchType = "lastcontact";
    } else {
      datapost.name = this.state.searchInputName;
      datapost.good = this.state.searchInputGood;
    }

    $.ajax({
      url: `${this.state.urlAPI}/api/thread/listreceiving`,
      data: datapost,
      type: "POST",
      dataType: "json",
      beforeSend: function () {
        this.setState({
          loading: <ProgressBar className="blue" />,
        });
      }.bind(this),
      success: function (data) {
        if (data) {
          this.setState({
            searchData: data,
            loading: <div></div>,
          });
        }
        // else{
        //     this.setState({
        //         loading  : <div align="center" style={{color:'red'}}>{data.message}</div>
        //     });
        // }
      }.bind(this),
    });
  }
  searchFromTTRS() {
    var obj = {
      controller: "user",
      action: "searchuser",
      name: "",
      lastname: "",
      identification: "",
      mobile: "",
      limit: "100",
      page: "",
    };

    if (this.state.searchType === "3") {
    } else if (this.state.searchType === "4") {
      obj.identification = this.state.searchInput;
    }

    switch (this.state.searchType) {
      case "3":
        var sp = this.state.searchInput.trim();
        sp = sp.split(" ");

        var name = sp[0];
        var lastname = sp[sp.length - 1];
        if (name === lastname) {
          lastname = "";
        }
        obj.name = name;
        obj.lastname = lastname;
        break;
      case "3.1":
        obj.name = this.state.searchInput.trim();
        break;
      case "3.2":
        obj.lastname = this.state.searchInput.trim();
        break;
      case "4":
        obj.identification = this.state.searchInput;
        break;
      default:
        break;
    }

    var test = {
      params: JSON.stringify(obj),
      appkey: "3D569F9F115833DD",
    };
    $.ajax({
      url: `https://api.ttrs.or.th/api.php`,
      data: test,
      type: "POST",
      dataType: "json",
      beforeSend: function () {
        this.setState({
          loading: <ProgressBar className="blue" />,
        });
      }.bind(this),
      success: function (data) {
        if (data.status === "OK") {
          this.setState({
            searchData: data.data,
            loading: <div></div>,
          });
        } else {
          this.setState({
            loading: (
              <div align="center" style={{ color: "red" }}>
                ไม่พบข้อมูล {this.state.searchType} {this.state.searchInput}
              </div>
            ),
          });
        }
      }.bind(this),
    });
  }

  onSearchTypeChange(event) {
    let index = event.target.selectedIndex;
    let el = event.target.childNodes[index];
    let option = el.getAttribute("from");
    this.setState({
      searchType: event.target.value,
      searchFrom: option,
      searchData: null,
    });
    if (event.target.value === "lastcontact") {
      this.setState({
        searchInputDisabled: true,
      });
    } else {
      this.setState({
        searchInputDisabled: false,
      });
    }
  }

  onSearchInputNameChange(event) {
    this.setState({ searchInputName: event.target.value });
  }
  onSearchInputGoodChange(event) {
    this.setState({ searchInputGood: event.target.value });
  }

  render() {
    return (
      <AuthProvider {...oidcConfig}>
        <div style={{ width: "96%", left: "2%", position: "fixed" }}>
          <CardPanel>
            <Row>
              <Col s={12}>
                <Col s={5}>
                  <Input
                    type="text"
                    label="ชื่อผู้ใช้"
                    s={12}
                    onChange={this.onSearchInputNameChange.bind(this)}
                  />
                </Col>
                <Col s={5}>
                  <Input
                    type="text"
                    label="เบอร์หูดี"
                    s={12}
                    onChange={this.onSearchInputGoodChange.bind(this)}
                  />
                </Col>
                <Col s={2}>
                  <div className="d-grid gap-2">
                    <Button
                      style={{ width: "73%" }}
                      // size="lg"
                      large
                      node="a"
                      onClick={(event) => this.searchUser(event)}
                      className="green"
                    >
                      ค้นหา
                    </Button>
                  </div>
                </Col>
              </Col>
            </Row>
            <Row>
              <Col s={12}>{this.state.loading}</Col>
            </Row>
            <Row>
              <Col s={12}>
                <RecevingSearch
                  agentid={this.state.agentid}
                  searchType={this.state.searchType}
                  searchFrom={this.state.searchFrom}
                  searchData={this.state.searchData}
                  searchInput={this.state.searchInput}
                />
              </Col>
            </Row>
          </CardPanel>
        </div>
      </AuthProvider>
    );
  }
  openChat() {
    // ReactDOM.render(
    //     <AgentRecevingCall
    //         type = "reactInternal"
    //         agentid = {this.state.agentid}
    //         identification = {this.state.userdata.identification}
    //         name = {this.state.userdata.name}
    //         lastname = {this.state.userdata.lastname}
    //         mobile = {this.state.userdata.mobile}
    //         token  = {this.state.userdata.notificationid_livechat}
    //         email = {this.state.userdata.email}
    //         mobileContact = {""}
    //         priority = {0}
    //     />, document.querySelector('.app'));
  }

  backToCheckIdentification() {
    this.setState({
      modalIsOpen: false,
    });
  }
}
export default ReceivingApp;
