import React, { Component } from "react";
import { Input, Row, Col, Button, ProgressBar, CardPanel } from "react-materialize";
import * as $ from "jquery";
import Modal from "react-modal";
import RecevingSearch from "./components/receiving_searchV3.js";
import { AuthProvider } from "oidc-react";
import { matchPath } from "react-router";
import "./css/style.css";

const oidcConfig = {
  onSignIn: async () => {
    console.log("Onsign");
    localStorage.clear();
    window.location.hash = "";
    await updateAccessToken();
  },
  authority: process.env.REACT_APP_ISSUER_AGENT,
  clientId: process.env.REACT_APP_CLIENT_ID_AGENT,
  redirectUri: `${process.env.REACT_APP_DOMAIN}${window.location.pathname}`,
  silent_redirect_uri: `${process.env.REACT_APP_DOMAIN}${window.location.pathname}`,
  response_type: "id_token token",
  scope: "openid profile email",
  automaticSilentRenew: true,
  filterProtocolClaims: true,
  loadUserInfo: true,
  monitorSession: true,
};

const updateAccessToken = async () => {
  var token_parse = JSON.parse(
    sessionStorage.getItem(
      `oidc.user:${process.env.REACT_APP_ISSUER_AGENT}:${process.env.REACT_APP_CLIENT_ID_AGENT}`,
    ),
  );

  if (token_parse !== null) {
    localStorage.setItem("access_token", token_parse.access_token);
    fetch(`${process.env.REACT_APP_URL_API}/v3/verifyagent`, {
      method: "POST",
      headers: { Authorization: `Bearer ${token_parse.access_token}` },
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        localStorage.setItem("family_name", response.data.family_name);
        localStorage.setItem("given_name", response.data.given_name);
        localStorage.setItem("preferred_username", response.data.preferred_username);
        const match = matchPath(window.location.pathname, {
          path: "/v3/receiving/:threadid",
          exact: true,
          strict: false,
        });
        updateAgentUsername(match.params.threadid, response.data.preferred_username);
      });
  }
};
const updateAgentUsername = (threadid, agentusername) => {
  fetch(`${process.env.REACT_APP_URL_API}/v3/agent/updateagentusername`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
    body: JSON.stringify({
      threadid: threadid,
      preferred_username: agentusername,
    }),
  })
    .then((response) => {
      return response.json();
    })
    .then((data) => {});
};
const alertCancelReceiving = {
  content: {
    backgroundColor: "white",
    top: "20%",
    with: "50%",
    bottom: "30",
    left: "30%",
    right: "30%",
    zIndex: "9999",
  },
};
class ReceivingApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      urlWS: process.env.REACT_APP_URL_WS,
      urlAPI: process.env.REACT_APP_URL_API,
      agentid: props.agentid,
      modalIsOpen: false,
      loading: <div></div>,
      loginConnect: "",
      searchType: "lastcontact",
      searchFrom: "livechat",
      searchData: null,
      searchInputName: "",
      searchInputGood: "",
      searchInputDisabled: true,
      threaddetail: null,
      selectTypemessage: null,
      typemessage: null,
      selectCompleteType: null,
      modalCancel: false,
      token: "",
    };
  }

  UNSAFE_componentWillMount() {
    Modal.setAppElement("body");
    document.title = "LiveChat Reveiving Call";
    // this.checkThreadStatus();
  }

  componentDidMount() {
    if (this.state.typemessage === null) {
      this.getTypeMessage();
    }
    if (this.state.threaddetail === null) {
      this.getThreadDetail();
    }
    this.searchFromLiveChat();
    setTimeout(
      function () {
        // updateAgentUsername(this.props.threadid)
        fetch(`${process.env.REACT_APP_URL_API}/v3/agent/updateagentbytoken`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
          body: JSON.stringify({
            threadid: this.props.threadid,
            agent_username: localStorage.getItem("preferred_username"),
            agent_name:
              localStorage.getItem("given_name") + " " + localStorage.getItem("family_name"),
          }),
        })
          .then((response) => {
            return response.json();
          })
          .then((data) => {});
      }.bind(this),
      2000,
    );
  }
  onSearchInputNameChange(event) {
    this.setState({ searchInputName: event.target.value });
  }
  onSearchInputGoodChange(event) {
    this.setState({ searchInputGood: event.target.value });
  }

  checkClientDetail() {
    var obj = {
      controller: "user",
      action: "showdetailusers",
      identification: this.state.identification,
    };
    var test = {
      params: JSON.stringify(obj),
      appkey: "3D569F9F115833DD",
    };
    $.ajax({
      url: `https://api.ttrs.or.th/api.php`,
      data: test,
      type: "POST",
      dataType: "json",
      beforeSend: function () {
        this.setState({
          loading: <ProgressBar className="blue" />,
        });
      }.bind(this),
      success: function (data) {
        if (data.status === "OK") {
          if (
            localStorage.getItem("receviving_threadstatus") === "receivingcall" ||
            localStorage.getItem("receviving_threadstatus") === "chat"
          ) {
            this.setState({
              userdata: data.userdata,
              loading: <div></div>,
            });
          } else {
            this.setState({
              userdata: data.userdata,
              loading: <div></div>,
              modalIsOpen: true,
            });
          }
        } else {
          this.setState({
            loading: (
              <div align="center" style={{ color: "red" }}>
                {data.message}
              </div>
            ),
          });
        }
      }.bind(this),
    });
  }
  getThreadDetail() {
    fetch(`${process.env.REACT_APP_URL_API}/thread/detail/${this.props.threadid}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.status.trim() === "abandon_receiving") {
          document.getElementById("noserviceType").checked = true;
          document.getElementById("typemessage").value = "type_id_62";

          this.setState({
            selectCompleteType: "noserviceType",
            selectTypemessage: 62,
          });
        }
      });
  }
  getTypeMessage() {
    fetch(`${process.env.REACT_APP_URL_API}/saveTypeMessage/viewtypemessage`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        this.setState({ typemessage: data });
      });
  }
  searchUser() {
    this.searchFromLiveChat();
  }

  searchFromLiveChat() {
    var datapost = {
      searchCondition: null,
      name: null,
      good: null,
    };

    if (this.state.searchInputName.trim() === "" && this.state.searchInputGood.trim() === "") {
      datapost.searchType = "lastcontact";
    } else {
      datapost.name = this.state.searchInputName;
      datapost.good = this.state.searchInputGood;
    }

    $.ajax({
      url: `${this.state.urlAPI}/api/thread/listreceiving`,
      data: datapost,
      type: "POST",
      dataType: "json",
      beforeSend: function () {
        this.setState({
          loading: <ProgressBar className="blue" />,
        });
      }.bind(this),
      success: function (data) {
        if (data) {
          this.setState({
            searchData: data,
            loading: <div></div>,
          });
        } else {
          this.setState({
            loading: (
              <div align="center" style={{ color: "red" }}>
                {data.message}
              </div>
            ),
          });
        }
      }.bind(this),
    });
  }
  searchFromTTRS() {
    var obj = {
      controller: "user",
      action: "searchuser",
      name: "",
      lastname: "",
      identification: "",
      mobile: "",
      limit: "100",
      page: "",
    };

    if (this.state.searchType === "3") {
    } else if (this.state.searchType === "4") {
      obj.identification = this.state.searchInput;
    }

    switch (this.state.searchType) {
      case "3":
        var sp = this.state.searchInput.trim();
        sp = sp.split(" ");

        var name = sp[0];
        var lastname = sp[sp.length - 1];
        if (name === lastname) {
          lastname = "";
        }
        obj.name = name;
        obj.lastname = lastname;
        break;
      case "3.1":
        obj.name = this.state.searchInput.trim();
        break;
      case "3.2":
        obj.lastname = this.state.searchInput.trim();
        break;
      case "4":
        obj.identification = this.state.searchInput;
        break;
      default:
        break;
    }

    var test = {
      params: JSON.stringify(obj),
      appkey: "3D569F9F115833DD",
    };
    $.ajax({
      url: `https://api.ttrs.or.th/api.php`,
      data: test,
      type: "POST",
      dataType: "json",
      beforeSend: function () {
        this.setState({
          loading: <ProgressBar className="blue" />,
        });
      }.bind(this),
      success: function (data) {
        if (data.status === "OK") {
          this.setState({
            searchData: data.data,
            loading: <div></div>,
          });
        } else {
          this.setState({
            loading: (
              <div align="center" style={{ color: "red" }}>
                ไม่พบข้อมูล {this.state.searchType} {this.state.searchInput}
              </div>
            ),
          });
        }
      }.bind(this),
    });
  }

  onSearchTypeChange(event) {
    let index = event.target.selectedIndex;
    let el = event.target.childNodes[index];
    let option = el.getAttribute("from");
    this.setState({
      searchType: event.target.value,
      searchFrom: option,
      searchData: null,
    });
    if (event.target.value === "lastcontact") {
      this.setState({
        searchInputDisabled: true,
      });
    } else {
      this.setState({
        searchInputDisabled: false,
      });
    }
  }
  onSelectTypemessageChange(event) {
    for (let node of event.target.children) {
      if (node.value === event.target.value) {
        this.setState({
          selectTypemessage: node.getAttribute("data-id"),
        });
      }
    }
  }
  onTypeCompleteChange(event) {
    this.setState({ selectCompleteType: event.target.value });
    switch (event.target.value) {
      case "completeType":
        document.getElementById("typemessage").value =
          `type_id_` + this.state.typemessage.completeType[0].type_id;
        this.setState({ selectTypemessage: this.state.typemessage.completeType[0].type_id });
        break;
      case "incompleteType":
        // document.getElementById("typemessage").value = "type_id_46";
        document.getElementById("typemessage").value =
          `type_id_` + this.state.typemessage.incompleteType[0].type_id;
        this.setState({ selectTypemessage: this.state.typemessage.incompleteType[0].type_id });
        break;
      case "noserviceType":
        // document.getElementById("typemessage").value = "type_id_60";
        document.getElementById("typemessage").value =
          `type_id_` + this.state.typemessage.noserviceType[0].type_id;
        this.setState({ selectTypemessage: this.state.typemessage.noserviceType[0].type_id });
        break;
      default:
        break;
    }
  }
  onSaveTypeMessage() {
    var chatcomplete = 0;
    switch (this.state.selectCompleteType) {
      case "completeType":
        chatcomplete = 1;
        break;
      case "incompleteType":
        chatcomplete = 0;
        break;
      default:
        chatcomplete = 2;
        break;
    }
    fetch(`${process.env.REACT_APP_URL_API}/v3/receiving/savetypemessage`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
      body: JSON.stringify({
        threadid: this.props.threadid,
        chatcomplete: chatcomplete,
        typemessage: this.state.selectTypemessage,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        window.close();
      });
  }
  onCancelReceiving() {
    fetch(`${process.env.REACT_APP_URL_API}/v3/receiving/cancelreceiving`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
      body: JSON.stringify({
        threadid: this.props.threadid,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        window.close();
      });
  }
  render() {
    return (
      // <AuthProvider {...oidcConfig}>
      <div>
        <br />
        <Modal isOpen={this.state.modalCancel} style={alertCancelReceiving}>
          <Row>
            <div align="center">
              <h5>ต้องการยกเลิกสาย Receiving นี้หรือไม่ </h5>
            </div>
          </Row>
          <Row>
            <Col s={6}>
              <button
                className="waves-effect waves-light btn btn-small full-width"
                onClick={this.onCancelReceiving.bind(this)}
              >
                ใช่
              </button>
            </Col>
            <Col s={6}>
              <button
                className="waves-effect waves-light btn btn-small full-width receiving-cancel"
                onClick={(event) => this.setState({ modalCancel: false })}
              >
                ไม่ใช่
              </button>
            </Col>
          </Row>
        </Modal>
        <Row>
          <Col s={1}></Col>
          <Col s={10}>
            <CardPanel>
              <Row>
                <Col s={2}>สถานะรับเรื่อง :</Col>
                <Col s={4}>
                  <input
                    type="radio"
                    id="completeType"
                    name="completeType"
                    value="completeType"
                    onChange={(event) => this.onTypeCompleteChange(event)}
                  />
                  <label htmlFor="completeType">สมบูรณ์</label>
                  <input
                    type="radio"
                    id="incompleteType"
                    name="completeType"
                    value="incompleteType"
                    onChange={(event) => this.onTypeCompleteChange(event)}
                  />
                  <label htmlFor="incompleteType">ไม่สมบูรณ์</label>
                  <input
                    type="radio"
                    id="noserviceType"
                    name="completeType"
                    value="noserviceType"
                    onChange={(event) => this.onTypeCompleteChange(event)}
                  />
                  <label htmlFor="noserviceType">ไม่ให้บริการ</label>
                </Col>
              </Row>
              <Row>
                <Col s={2}>ประเภทการรับเรื่อง : </Col>
                <Col s={6}>
                  <div>
                    <select
                      name="typemessage"
                      id="typemessage"
                      className="select-dropdown"
                      label="ส่วนค้นหา"
                      onChange={this.onSelectTypemessageChange.bind(this)}
                      style={{ display: "block" }}
                    >
                      {this.state.selectCompleteType === null ? (
                        <></>
                      ) : this.state.typemessage === null ? (
                        <></>
                      ) : (
                        this.state.typemessage[this.state.selectCompleteType].map(
                          (listItem, index) => {
                            if (this.state.selectTypemessage === listItem.type_id) {
                              return (
                                <option
                                  selected
                                  id={`type_id_${listItem.type_id}`}
                                  value={`type_id_${listItem.type_id}`}
                                  key={index}
                                  data-id={listItem.type_id}
                                  from={listItem.name}
                                >
                                  {listItem.name}
                                </option>
                              );
                            } else {
                              return (
                                <option
                                  id={`type_id_${listItem.type_id}`}
                                  value={`type_id_${listItem.type_id}`}
                                  key={index}
                                  data-id={listItem.type_id}
                                  from={listItem.name}
                                >
                                  {listItem.name}
                                </option>
                              );
                            }
                          },
                        )
                      )}
                    </select>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col s={2} />
                <Col s={3}>
                  <button
                    className="waves-effect waves-light btn btn-small full-width"
                    onClick={this.onSaveTypeMessage.bind(this)}
                  >
                    บันทึก
                  </button>
                </Col>
                <Col s={3}>
                  <button
                    className="waves-effect waves-light btn btn-small full-width receiving-cancel"
                    onClick={(event) => this.setState({ modalCancel: true })}
                  >
                    ยกเลิก
                  </button>
                </Col>
              </Row>
            </CardPanel>
          </Col>
        </Row>
        <Row style={{ marginTop: "-20px" }}>
          <Col s={1} />
          <Col s={10}>
            <CardPanel>
              <Row>
                <Col s={5}>
                  <Input
                    type="text"
                    label="ชื่อผู้ใช้"
                    s={12}
                    onChange={this.onSearchInputNameChange.bind(this)}
                  />
                </Col>
                <Col s={5}>
                  <Input
                    type="text"
                    label="เบอร์หูดี"
                    s={12}
                    onChange={this.onSearchInputGoodChange.bind(this)}
                  />
                </Col>
                <Col s={2}>
                  <Button
                    large
                    node="a"
                    onClick={(event) => this.searchUser(event)}
                    className="green"
                  >
                    ค้นหา
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col s={12}>{this.state.loading}</Col>
              </Row>
              <Row>
                <Col s={12}>
                  <RecevingSearch
                    threadid={this.props.threadid}
                    agentid={this.state.agentid}
                    searchType={this.state.searchType}
                    searchFrom={this.state.searchFrom}
                    searchData={this.state.searchData}
                    searchInput={this.state.searchInput}
                  />
                </Col>
              </Row>
            </CardPanel>
          </Col>
        </Row>
      </div>
      // </AuthProvider>
    );
  }

  backToCheckIdentification() {
    this.setState({
      modalIsOpen: false,
    });
  }
}
export default ReceivingApp;
